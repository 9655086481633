import base from "./base";
import axios from "../utils/http";
const api = {
    getPotential(params) {
        return axios.post(base.baseUrl + base.potential, params);
    },
    getChannel(params) {
        return axios.post(base.baseUrl + base.channel, params);
    },
  savemessage(params){
      return axios.post(base.baseUrl + base.savemsg, params)
  }
};
export default api;
