import axios from "axios";
import qs from "querystring";

/**
 * 配置request请求时的默认参数
 */
let BASEURL = "https://api.matefix.cn";
let dev = window.location.hostname.split(".")[0];
if (dev === "localhost" || dev === "192" || dev === "test" || dev === "172") {
    // BASEURL = "https://test.api.matefix.cn";
    BASEURL = "http://t2admin.matefix.cn:8002";
}

// 创建axios实例
// 创建请求时可以用的配置选项
var instance = axios.create({ baseURL: BASEURL, timeout: 6000 });
// axios的全局配置

instance.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";

// 添加请求拦截器(post只能接受字符串类型数据)
instance.interceptors.request.use(
    function(config) {
        if (config.method === "post") {
            config.data = qs.stringify(config.data);
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    // 响应包含以下信息data,status,statusText,headers,config
    (res) =>
    res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res),
    (err) => {
        console.log(err);
        const { response } = err;
        if (response) {
            errorHandle(response.status, response.data);
            return Promise.reject(response);
        } else {
            this.$message("请求失败");
        }
    }
);
const errorHandle = (status, other) => {
    switch (status) {
        case 400:
            this.$message("信息校验失败");
            break;
        case 401:
            this.$message("认证失败");
            break;
        case 403:
            this.$message("token校验失败");
            break;
        case 404:
            this.$message("请求的资源不存在");
            break;
        default:
            this.$message(other);
            break;
    }
};
export default instance;
