import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
  path: "/index",
  name: "layout",
  component: () =>
    import ( /* webpackChunkName: "about" */ "../components/Layout.vue"),
  children: [{
    path: "/potential",
    name: "potential",
    component: () =>
      import (
        /* webpackChunkName: "about" */
        "../views/potential/potential.vue"
        ),
  },
    {
      path: "/success",
      name: "success",
      component: () =>
        import (
          /* webpackChunkName: "about" */
          "../views/potential/success.vue"
          ),
    },
    {
      path: "/channel",
      name: "channel",
      component: () =>
        import (
          /* webpackChunkName: "about" */
          "../views/channel/potential.vue"
          ),
    },
  ],
},
  {
    path: "/",
    name: "common",
    component: () =>
      import ( /* webpackChunkName: "about" */ "../components/CommonLayout.vue"),
    children: [
      // {
      //         path: "/",
      //         name: "home",
      //         component: () =>
      //             import (
      //                 /* webpackChunkName: "about" */
      //                 "../views/home/index.vue"
      //             ),
      //     },
      {
        path: "/",
        name: "homePage",
        component: () =>
          import (
            /* webpackChunkName: "about" */
            "../views/homePage/index.vue"
            ),
      },
      {
        path: "/productIntroduction",
        name: "ProductIntroduction",
        component: () =>
          import (
            /* webpackChunkName: "about" */
            "../views/homePage/ProductIntroduction.vue"
            ),
      },
      {
        path: "/serve",
        name: "serve",
        component: () =>
          import (
            /* webpackChunkName: "about" */
            "../views/serve/index.vue"
            ),
      },
      {
        path: "/about",
        name: "about",
        component: () =>
          import (
            /* webpackChunkName: "about" */
            "../views/about/index.vue"
            ),
      },
      {
        path: "/solution",
        name: "solution",
        component: () =>
          import (
            "../views/solution/index.vue"
            ),
      },
      {
        path: "/pdSoluting",
        name: "pdSoluting",
        component: () =>
          import (
            "../views/solution/pdSoluting.vue"
            ),
      },
      {
        path: "/zcSolution",
        name: "zcSolution",
        component: () =>
          import (
            "../views/solution/zcSolution.vue"
            ),
      },
      {
        path: "/client",
        name: "client",
        component: () =>
          import (
            /* webpackChunkName: "about" */
            "../views/client/index.vue"
            ),
      },
      {
        path: "/ruler",
        name: "ruler",
        component: () =>
          import (
            "../views/ruler/index.vue"
            ),
      },
    ],
  },
  {
    path: "/free",
    name: "free",
    component: () =>
      import (
        /* webpackChunkName: "about" */
        "../views/homePage/free.vue"
        ),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
